import React, { useEffect, useRef, useState } from 'react';
import { DatePicker, Drawer, Modal, Popconfirm, Radio, Space, Switch, Table, Typography} from 'antd';
import type { TableColumnsType, TableProps } from 'antd';
import { Button, Form, Input, Select, Card } from 'antd';

import {
  CheckOutlined,
  ExclamationCircleOutlined,
  FileExcelOutlined ,
  CheckCircleOutlined,
  ReloadOutlined
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { DataTable } from '../common/DataTable';
import Column from 'antd/es/table/Column';
import { formatDateInTimeZone } from '../utils/CommonFn';
import TooltipParagraph from '../common/TooltipParagraph';
import { getMasterTypeSelectorValue } from '../common/slice-master';
import { use } from 'echarts';
import ticketInventory, { deleteFileByIdAndComponentName, downloadPreviewExcel, updateTicketsStatusForPreviewPage } from './slice-ticketInventory';
import { PreAuthz } from '../security/Authz';


const TicketStatus = {Pending:"Pending",Approved:"Approved",Rejected:"Rejected",OnHold :"ON_HOLD", Void :"Void"} as any;

const TicketStatusReverse = { } as any;

Object.keys(TicketStatus).forEach((e:any)=>{
  TicketStatusReverse[TicketStatus[e]] = e
});

const StatusRadioGroup = Object.keys(TicketStatus).map((e:any)=><Radio.Button value={TicketStatus[e]}>{e}</Radio.Button>)





export const TicketInventoryUploadList = () => {
  const { inventoryData, ticketStatusUpdateloading, uploadEntryDeleteLoading, downloadPreviewExcelloading } = useSelector((state: any) => state.ticketInventory);
  let { masterSelectValuesMap } = useSelector((state: any) => state.master);
  masterSelectValuesMap = masterSelectValuesMap || {}
  const dispatch = useDispatch()
  const datatableref = useRef();
  const uploadDatatableRef = useRef();
  const [state, setState] = useState<any>({drawer: undefined, selectedRows: []});
  const [modal, contextHolder] = Modal.useModal();

  const isDiasableActionBtn = state.selectedRows === undefined || state.selectedRows.length === 0;

  useEffect(() => {
    dispatch(getMasterTypeSelectorValue('Trip Ticket Inventory'));
  }, [])


  const markAsStatus = (status:any) => {

    modal.confirm({
      title: 'Confirmation for '+ TicketStatusReverse[status],
      icon: <ExclamationCircleOutlined />,
      content: 'After '+TicketStatusReverse[status]+' tickets, status will be updated and it will impact ticket inventory board',
      okText: 'Procced',
      onOk: (onOk) => {
        var d = state.selectedRows.map((e:any)=>({id: e, status: status }));
        console.log(d)
        dispatch(updateTicketsStatusForPreviewPage({status:status, body:d }))
        .then((e:any)=>{
            // refresh the table
            (datatableref.current as any).refresh()
            setState((st:any)=>({...st, selectedRows: []}))
            onOk();
        });
      },
      cancelText: 'Cancel',
    });
    // call api to update status
   

  }

  const [status, setStatus] = useState<any>('Pending');

  const [uploadListStatus, setUploadListStatus] = useState<any>('Pending');

  const handleStatusChange = (e: any) => {
    setState((st:any)=>({...st, selectedRows: []}))
    setStatus(e.target.value);
  };

  const handleUploadListStatusChange = (e: any) => {
    setState((st:any)=>({...st, selectedRows: []}))
    setUploadListStatus(e.target.value);
  };

  useEffect(() => {
    if(datatableref.current)
        (datatableref.current as any).refresh()
    
    if(uploadDatatableRef.current) 
      (uploadDatatableRef.current as any).refresh()

  }, [status, uploadListStatus]);


const downloadExcel = () => {
  dispatch(downloadPreviewExcel({id: state.drawer, status}))
}

return  (

       <>
       {contextHolder}
     {state.drawer?  <Drawer 
    placement={'right'}
    open={state.drawer} 
    onClose={() => {
      setState((st:any)=>({...st, drawer: undefined}));
      (uploadDatatableRef.current as any).refresh()
    }
    } 
    width={'calc(100% - 100px)'}
    title={
     <>
      <PreAuthz allowedRoles='superadmin' hideResult={true} >
        { status === TicketStatus.Pending || status === TicketStatus.OnHold || status === TicketStatus.Void || status === TicketStatus.Rejected ? <Button disabled={isDiasableActionBtn} type='primary' style={{float: 'right', marginRight: 10}} onClick={()=>markAsStatus(TicketStatus.Approved)} loading={ticketStatusUpdateloading === TicketStatus.Approved}><CheckCircleOutlined /> Approve</Button>  : null}
      </PreAuthz>
      <PreAuthz allowedRoles='superadmin' hideResult={true} >
        { status === TicketStatus.Pending || status === TicketStatus.Approved ? <Button  disabled={isDiasableActionBtn} type='primary' danger style={{float: 'right', marginRight: 20}} onClick={()=>markAsStatus(TicketStatus.Rejected)} loading={ticketStatusUpdateloading === TicketStatus.Rejected}> Reject </Button>  : null}
      </PreAuthz>
      <PreAuthz allowedRoles='superadmin,ticketmanager' hideResult={true} >
        { status === TicketStatus.Pending || status === TicketStatus.Approved ? <Button  disabled={isDiasableActionBtn} style={{float: 'right', marginRight: 10}} onClick={()=>markAsStatus(TicketStatus.OnHold)} loading={ticketStatusUpdateloading === TicketStatus.OnHold}>On Hold </Button>  : null}
      </PreAuthz>    
      <PreAuthz allowedRoles='superadmin' hideResult={true} >  
        { status === TicketStatus.Pending || status === TicketStatus.Approved || status === TicketStatus.Rejected ? <Button   disabled={isDiasableActionBtn} style={{float: 'right', marginRight: 10}} onClick={()=>markAsStatus(TicketStatus.Void)} loading={ticketStatusUpdateloading === TicketStatus.Void}> Void </Button>  : null}
      </PreAuthz>
      <Button disabled={downloadPreviewExcelloading}  
      loading={downloadPreviewExcelloading} 
       style={{float: 'right', marginRight: 10, }}
        onClick={()=>downloadExcel()}><FileExcelOutlined  /> Export </Button>
     {/* <Button disabled={status== ''}  danger type='primary' style={{float: 'right', marginRight: 20}}  
     onClick={()=>{
      modal.confirm({
        title: 'Confirmation for rejection',
        icon: <ExclamationCircleOutlined />,
        content: 'After reject tickets will be deleted and uploaded file will be cleaned for system',
        okText: 'Procced',
        onOk: () => {
          // call approve api for rejection
        },
        cancelText: 'Cancel',
      });

     }}><CloseCircleOutlined /> Reject </Button>
     <Button type='primary' style={{float: 'right', marginRight: 20}} 
     
     onClick={()=>{
      modal.confirm({
        title: 'Confirm to procced',
        icon: <ExclamationCircleOutlined />,
        content: 'After approve tickets will be ready for download',
        okText: 'Procced',
        onOk: () => {
          // call approve api for approval
        },
        cancelText: 'Cancel',
      });

     }}><CheckCircleOutlined /> Approve </Button> */}
   
     {/* <Button   style={{float: 'right', marginRight: 20}}  onClick={()=>{}}><ReloadOutlined /> Refresh </Button> */}
            
     </> 
    
    
    }
    
    // footer={(_, { OkBtn, CancelBtn }) => (
    //   <>
    //     <CancelBtn />
    //   </>
    // )}
    destroyOnClose>
       
       <DataTable
            onRefresh={()=> setState((statea:any)=> ({ ...statea, selectedRows: []}))}
            checkboxProps={(record:any)=>({disabled: record.downloadPrimaryId})}
            selectedRows={status === '' ? undefined: state.selectedRows}
            onRowSlection={(selectedRow:any)=>setState((statea:any)=> ({ ...statea, selectedRows: selectedRow}))}
            ref={datatableref}
            title={<>
              <Radio.Group value={status} defaultValue={status} onChange={handleStatusChange}>
        <Radio.Button value="All">All</Radio.Button>
        {StatusRadioGroup}
        
      </Radio.Group>
            </>} 
            key="id" componentuniquekey="datatableuploadfilecontentlist" 
            lazyPagination={true}
            rowClassName='morecontent'
            buildApiUrl={(pageNumber, pagesize)=>`previewFileContent?uploadId=${state.drawer}&limit=${pagesize}&page=${pageNumber}&status=${status}`} 
            >
        <Column title="Status"  render={(el:any)=> el.status} /> 
        <Column title="id"  render={(el:any)=> el.id}/> 
        <Column title="Download"  render={(el:any)=> el.downloadPrimaryId ? 'Yes': 'No'}/> 
        
        <Column title="Ticket Type"  render={(el:any)=> (el.date && el.time) ? "Dated" : "Open Date"}/> 
        <Column title="Is Media Code" dataIndex="mediaCode"  render={(el:any)=> (el) ? "Yes" : "No"} />  
        <Column title="Tour" dataIndex="tourname"  />  
        <Column title="PNR" dataIndex="key"  />
        <Column title="QR/Bar Code" dataIndex="qr"  />
        <Column title="Cost" dataIndex="cost"  />
        <Column title="Input Expiry" dataIndex="inputExpiry"  render={(el:any)=>formatDateInTimeZone(el)} />
        <Column title="Expiry" dataIndex="expiry"  render={(el:any)=>formatDateInTimeZone(el)}/>
        <Column title="Ticket Type" dataIndex="ticketType"  />
        <Column title="Date" dataIndex="date"  />
        <Column title="Time" dataIndex="time"  />
        <Column title="Last Update Date" render={(el:any)=>formatDateInTimeZone(el.updated)}/>
    </DataTable>

    </Drawer> 
     : null} 
    <DataTable
            title={<>
              <Radio.Group value={uploadListStatus} defaultValue={uploadListStatus} 
                onChange={handleUploadListStatusChange}>

                <Radio.Button value="All">All</Radio.Button>
                {StatusRadioGroup}
                
              </Radio.Group>
            </>}  
            key="id" componentuniquekey="datatableuploadfilelist" 
            lazyPagination={true}
            onRefresh={()=> setState((statea:any)=> ({ ...statea, selectedFileRows: []}))}
            ref={uploadDatatableRef}
            checkboxProps={(record:any)=>{
              return ({disabled: record.pendingCount === 0})
            }}
            selectedRows={state.selectedFileRows || []}
            onRowSlection={(selectedRow:any)=>setState((statea:any)=> ({ ...statea, selectedFileRows: selectedRow}))}
            extra={<Button 
              onClick={()=>setState((st:any)=>({...st, drawer: state.selectedFileRows.join(',')}))}
              disabled={!(state.selectedFileRows && state.selectedFileRows.length > 0)}>Preview</Button>}

            datatransform={(el:any) => el.map((e:any)=>({...e, extraj: JSON.parse(window.atob(e.extra))}))}
            buildApiUrl={(pageNumber, pagesize)=>`fetchUploadedFilesDetails?limit=${pagesize}&page=${pageNumber}&status=${uploadListStatus}`} 
            rowClassName={(record:any) => record.status === 'processeed_error' ? 'error' : ''}
          >
        <Column title="Product Name" dataIndex="extraj" render={(el:any)=>(masterSelectValuesMap[el.inventoryProductId] || {}).masterValue} />
        <Column title="File Name" dataIndex="fileName"  />
        <Column title="Cost Per Ticket" dataIndex="extraj" render={(el:any)=>el.cost} />
        <Column title="Input Expiry" dataIndex="extraj" render={(el:any)=>formatDateInTimeZone(el.expiry)} />
        <Column title="Uploaded By" dataIndex="userName"  />
        <Column title="Uploaded Date" render={(el:any)=>formatDateInTimeZone(el.created)}/>
        <Column title="Pending/Total" render={(el:any)=> ({
            props: {
              style: { background: el.pendingCount > 0 ? "#ffbdbd" : "" }
            },
            children: <div>{`${el.pendingCount}/${el.totalCount}`}</div>
          })} />
        
        <Column title="File Process Status" dataIndex="status"  />
        <Column title="Comment" dataIndex="comment" render={(e:any)=><TooltipParagraph style={{width: '75px'}} ellipsis={true}  >{e}</TooltipParagraph>} />
        <Column title="Preview"  render={(el:any)=>
          <Button disabled={el.status === 'processeed_error'} onClick={()=>setState((st:any)=>({...st, drawer: el.id}))}>Preview</Button> } 
        />
        <Column title="Action"  render={(el:any)=>
        <>
        <Popconfirm
          title="Delete Confirmation"
          description="Are you sure, you would like to delete this file?"
          okText="Yes"
          cancelText="No"
          onConfirm={() => dispatch(deleteFileByIdAndComponentName(el)).then(() => {
              if(uploadDatatableRef.current)
                  (uploadDatatableRef.current as any).refresh();
            })}
        >
          <Button 
            loading={uploadEntryDeleteLoading === el.id}
            disabled={el.status !== 'processeed_error' && (el.approvedCount > 0)}
            >
            Delete File
          </Button>
        </Popconfirm>
        </>
        } />
    </DataTable>
     
  </>);
}





